import * as React from "react";
import { Fragment } from "react";
import { useState, useEffect } from "react";
import {
  required,
  minLength,
  maxLength,
  minValue,
  maxValue,
  number,
  regex,
  email,
  choices,
} from "react-admin";
import {
  useRedirect,
  List,
  Datagrid,
  TextField,
  Filter,
  TextInput,
  BooleanInput,
  EditButton,
  Edit,
  Create,
  SimpleForm,
  NumberInput,
  BulkDeleteWithConfirmButton,
  Button,
  SaveButton,
  Toolbar,
  TopToolbar,
  DeleteWithConfirmButton,
  Show,
  SimpleShowLayout,
  ImageField,
  ShowButton,
  ReferenceField,
  TabbedShowLayout,
  Tab,
  ReferenceManyField,
  DateField,
  useDataProvider,
  SelectInput,
  Loading,
  Error,
} from "react-admin";
import { apiUrl } from "../../../global";
import { httpClient } from "../../dataProvider";
import qs from "qs";
import { LinearProgress, Alert, Snackbar } from "@mui/material";

const SelectByEnum = (props: any) => {
  let { EnumName, OnlyText } = props;
  const dataProvider = useDataProvider();
  const [data, setData] = useState();
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState(null);
  useEffect(() => {
    httpClient(`${apiUrl}/Public/GetEnumVals`, {
      method: "POST",
      contentType: "application/x-www-form-urlencoded",
      body: qs.stringify({ EnumName, OnlyText }),
    }).then(({ json }) => {
      setLoading(false);
      if (json.Code == 1) setData(json.Result);
      else setError(json.Message);
    });
  }, []);

  const handleClose = () => {
    setError(null);
  };

  if (loading) return <LinearProgress />;
  if (error)
    return (
      <Snackbar
        open={error ? true : false}
        autoHideDuration={6000}
        onClose={handleClose}
      >
        <Alert severity="error">{error}</Alert>
      </Snackbar>
    );
  if (!data) return null;

  return <SelectInput {...props} choices={data} />;
};

const TextByEnum = (props: any) => {
  const dataProvider = useDataProvider();
  const [data, setData] = useState<any>();
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState(null);
  useEffect(() => {
    httpClient(`${apiUrl}/Public/GetEnumVals`, {
      method: "POST",
      contentType: "application/x-www-form-urlencoded",
      body: qs.stringify({
        EnumName: props.EnumName,
        Key: props.record[props.source],
      }),
    }).then(({ json }) => {
      setLoading(false);
      if (json.Code == 1) setData(json.Result);
      else setError(json.Message);
    });
  }, []);

  const handleClose = () => {
    setError(null);
  };

  if (loading) return <LinearProgress />;
  if (error)
    return (
      <Snackbar
        open={error ? true : false}
        autoHideDuration={6000}
        onClose={handleClose}
      >
        <Alert severity="error">{error}</Alert>
      </Snackbar>
    );
  if (!data) return null;

  return <div>{data[0].name}</div>;
};

export { SelectByEnum, TextByEnum };
