import * as React from 'react';
import { Fragment } from 'react';
import { makeStyles } from 'tss-react/mui';
import { required, minLength, maxLength, minValue, maxValue, number, regex, email, choices, Form, useResourceContext, useNotify, useGetRecordId, useRefresh, useRecordContext, FunctionField } from 'react-admin';
import {
  useListContext,
  useRedirect,
  List,
  Datagrid,
  Filter,
  Edit,
  Create,

  Toolbar,
  TopToolbar,
  DeleteWithConfirmButton,
  BulkDeleteWithConfirmButton,
  Show,
  SimpleShowLayout,

  ReferenceField,
  TextField,
  ImageField,
  BooleanField,

  SimpleForm,
  ReferenceInput,
  SelectInput,
  TextInput,
  NumberInput,
  BooleanInput,
  NullableBooleanInput,

  Button,
  EditButton,
  ShowButton,
  SaveButton,
} from 'react-admin';
import { useForm, useFormContext } from 'react-hook-form';
import { Button as MButton, TextField as MTextField, Box, useMediaQuery, Stack } from '@mui/material';
import { PreviewImage, UpLoadFile } from './custom/UpLoadFile';
import ArrowBackIcon from '@mui/icons-material/ArrowBack';
import SearchIcon from '@mui/icons-material/Search';
import ClearAllIcon from '@mui/icons-material/ClearAll';
//import ResetOrderNum from './Member_ResetOrderNum';
import Dialog from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import DialogContentText from '@mui/material/DialogContentText';
import DialogTitle from '@mui/material/DialogTitle';
import { POST, httpClient } from '../dataProvider';
import { apiUrl } from '../../global';

{/* 
  import { MemberList, MemberCreate, MemberEdit, MemberShow } from './components/Member';

  <Resource name="CMSMember" list={MemberList} create={MemberCreate} edit={MemberEdit} show={MemberShow} />
  <Resource name="CMSMember" list={ListGuesser} create={EditGuesser} edit={EditGuesser} edit={ShowGuesser} /> 
*/}

const useStyles = makeStyles()((theme) => {
  return {
    imageField: {
      '& img.RaImageField-image': { width: 60 }
    },
  };
});


const useTxtData = {
  page: {
    tableName: '成员',
  },
  table: {
    id: 'id',
    WeiXin_OpenID: '微信OpenID',
    UserName: '昵称',
    UserMobile: 'UserMobile',
    UserPwd: 'UserPwd',
    UserSex: '性别',
    UserTouXiang: '头像',
    CreateTime: '创建时间',
    LastLoginTime: '登陆时间',
  }
};

//分页列表页面
export const MemberList = () => {
  const isSmall = useMediaQuery((theme: any) => theme.breakpoints.down('sm'));
  // 筛选器模块
  // const Filters = () => {
  //   return (
  //     <Filter>
  //       <TextInput label="ID" source="id" alwaysOn />
  //     </Filter>
  //   );
  // };
  const MobileFilters = [
    <TextInput label="关键词" source="Key" alwaysOn resettable />
  ];
  const Filters = (props: any) => {
    const { displayedFilters, filterValues, setFilters, hideFilter, refetch } = useListContext();
    if (isSmall) return null;
    if (props.context === "button") return null;
    const onSubmit = (values: any) => { if (Object.keys(values).length > 0) { setFilters(values, displayedFilters); } else { setFilters({}, displayedFilters); } refetch(); };
    const resetFilter = () => { setFilters({}, displayedFilters); };
    return (
      <div>
        <Form onSubmit={onSubmit} defaultValues={filterValues}>
          <Stack direction={'row'} alignItems={'baseline'} spacing={1.5}>
            <TextInput label="关键词" source="Key" alwaysOn resettable />
            <MButton variant="contained" color="primary" type="submit" startIcon={<SearchIcon />}>搜索</MButton>
            <MButton variant="outlined" onClick={resetFilter} startIcon={<ClearAllIcon />}>重置</MButton>
          </Stack>
        </Form>
      </div>
    );
  };

  //批量操作模块
  const PostBulkActionButtons = () => {
    return (
      <Fragment>
        {/* <ResetOrderNum label="重置排序"  /> */}
        {/* <BulkDeleteWithConfirmButton confirmTitle="删除确认" confirmContent="确认要删除所选记录吗？" mutationMode='undoable' /> */}
      </Fragment>
    );
  };
  //行右侧功能按钮
  const RowAction = () => {
    return (
      <div style={{ textAlign: 'right' }}>
        {/* <EditButton /><br /> */}
        <ShowButton /><br />
        {/* <DeleteWithConfirmButton confirmTitle="删除确认" confirmContent="确认要删除该记录吗？" /> */}
      </div>
    );
  }

  const { classes } = useStyles();

  return (
    <List
      title={useTxtData.page.tableName + '列表'}
      sort={{ field: 'id', order: 'DESC' }}
      // filters={<Filters />}
      exporter={false}
      sx={{ "& .MuiToolbar-root .MuiToolbar-root": { height: 0, minHeight: 0 } }}
    >
      <Datagrid
        // bulkActionButtons={<PostBulkActionButtons />}
        bulkActionButtons={false}
      >
        {/* <TextField source="id" /> */}
        {/* <TextField label="名称" source="Name" /> */}
        {/* <TextField label="排序" source="OrderNum" />  */}
        <TextField label={useTxtData.table.id} source="id" />
        {/* <TextField label={useTxtData.table.WeiXin_OpenID} source="WeiXin_OpenID" /> */}
        <FunctionField label={useTxtData.table.WeiXin_OpenID} render={({ WeiXin_OpenID, CunMingId }: any) =>
          <>
            <span>{WeiXin_OpenID}</span><br />
            <span>{CunMingId > 0 ? '已绑定村名' : ''}</span>
          </>
        } />
        <TextField label={useTxtData.table.UserName} source="UserName" />
        {/* <TextField label={useTxtData.table.UserMobile} source="UserMobile" /> */}
        {/* <TextField label={useTxtData.table.UserPwd} source="UserPwd" /> */}
        {/* <TextField label={useTxtData.table.UserSex} source="UserSex" /> */}
        {/* <TextField label={useTxtData.table.UserTouXiang} source="UserTouXiang" /> */}
        <ImageField label={useTxtData.table.UserTouXiang} source="UserTouXiang" className={classes.imageField} />
        <TextField label={useTxtData.table.CreateTime} source="CreateTime" />
        <TextField label={useTxtData.table.LastLoginTime} source="LastLoginTime" />
        <RowAction />
      </Datagrid>
    </List>
  );
};

////////////////////////////////////////////////////////////////////////
////////////////////////////////////////////////////////////////////////

//SHOW页面
export const MemberShow = () => {
  const recordId = useGetRecordId();
  const resource = useResourceContext();
  const redirect = useRedirect();

  const ShowActions = () => {
    const record = useRecordContext();
    if (record == null) return null;

    let { CunMingId } = record;
    return (
      <TopToolbar>
        <Button label="返回列表" onClick={() => redirect(`/${resource}`)} variant="text">
          <ArrowBackIcon />
        </Button>
        <EditButton />
        {CunMingId > 0 ? null : <BindCunMing />}
      </TopToolbar>
    );
  };

  const BindCunMing = () => {
    const notify = useNotify();
    const refresh = useRefresh();
    const [id, setId] = React.useState();
    const [open, setOpen] = React.useState(false);
    const handleClickOpen = () => {
      setOpen(true);
    };
    const handleClose = () => {
      setOpen(false);
    };

    const submit = () => {
      console.log('id', id)
      POST('/Admin/CMSMember/BindCunMing', { recordId, cmId: id })
        .then(({ json: { Code, Message, Result } }: any) => {
          if (Code == 0)
            notify(Message, { type: 'error' })
          else {
            notify(Message, { type: 'success' })
            refresh()
          }
        })
    }
    return (
      <div>
        <Button label="绑定村名" variant='contained' onClick={handleClickOpen} />
        <Dialog open={open} onClose={handleClose}>
          <DialogTitle>绑定村名</DialogTitle>
          <DialogContent sx={{ minWidth: 200 }}>
            <DialogContentText>
              输入村民ID进行绑定
            </DialogContentText>
            <MTextField
              autoFocus
              margin="dense"
              label=""
              type="text"
              fullWidth
              variant="standard"
              onChange={(e: any) => setId(e.target.value)}
            />
          </DialogContent>
          <DialogActions>
            <MButton onClick={handleClose}>取消</MButton>
            <MButton onClick={submit}>绑定</MButton>
          </DialogActions>
        </Dialog>
      </div>
    )
  }

  const classes = useStyles();
  return (
    <Show title={'查看' + useTxtData.page.tableName} actions={<ShowActions />}>
      <SimpleShowLayout>
        {/* <TextField source="id" /> */}
        {/* <TextField label="名称" source="Name" /> */}
        {/* <TextField label="排序" source="OrderNum" />  */}
        <TextField label={useTxtData.table.id} source="id" />
        <TextField label={useTxtData.table.WeiXin_OpenID} source="WeiXin_OpenID" />
        <TextField label={useTxtData.table.UserName} source="UserName" />
        <TextField label={useTxtData.table.UserMobile} source="UserMobile" />
        <TextField label={useTxtData.table.UserPwd} source="UserPwd" />
        <TextField label={useTxtData.table.UserSex} source="UserSex" />
        <TextField label={useTxtData.table.UserTouXiang} source="UserTouXiang" />
        <TextField label={useTxtData.table.CreateTime} source="CreateTime" />
        <TextField label={useTxtData.table.LastLoginTime} source="LastLoginTime" />
      </SimpleShowLayout>
    </Show>
  );
}

////////////////////////////////////////////////////////////////////////
////////////////////////////////////////////////////////////////////////

//编辑页面
export const MemberEdit = () => {
  const resource = useResourceContext();
  const redirect = useRedirect();

  const PostEditActions = () => {
    return (
      <TopToolbar >
        <Button label="返回列表" onClick={() => redirect(`/${resource}`)} variant="text">
          <ArrowBackIcon />
        </Button>
      </TopToolbar>
    );
  };
  const PostEditToolbar = () => {
    return (
      <Toolbar >
        <SaveButton />
      </Toolbar>
    );
  };
  return (
    <Edit redirect={`/${resource}`} title={'编辑' + useTxtData.page.tableName} actions={<PostEditActions />}>
      <MyForm Edit={true} toolbar={<PostEditToolbar />} />
    </Edit>
  );
};

////////////////////////////////////////////////////////////////////////
////////////////////////////////////////////////////////////////////////

//创建页面
export const MemberCreate = () => {
  const resource = useResourceContext();
  const redirect = useRedirect();
  const notify = useNotify();

  const PostCreateActions = () => {
    return (
      <TopToolbar>
        <Button label="返回列表" onClick={() => redirect(`/${resource}`)} variant="text" >
          <ArrowBackIcon />
        </Button>
      </TopToolbar>
    );
  };
  const PostCreateToolbar = () => {
    const { reset, resetField, handleSubmit, setValue, getValues, } = useFormContext();
    return (
      <Toolbar>
        <SaveButton alwaysEnable label="保存&列表" />
        <SaveButton alwaysEnable type='button' label="保存&继续" variant="text" mutationOptions={{
          onSuccess: () => {
            notify('创建成功', { type: 'success' });
            reset();
          }
        }} />
      </Toolbar>
    );
  };

  return (
    <Create redirect={`/${resource}`} title={'新增' + useTxtData.page.tableName} actions={<PostCreateActions />}>
      <MyForm Create={true} toolbar={<PostCreateToolbar />} />
    </Create>
  );
};

////////////////////////////////////////////////////////////////////////
////////////////////////////////////////////////////////////////////////
//Create和Edit公用表单
const MyForm = (props: any) => {
  let { Edit, Create } = props;
  return (
    <SimpleForm {...props} >
      {/* <TextInput source="Name" /> */}
      {/* <NumberInput source="OrderNum" /> */}
      <TextInput label={useTxtData.table.id} source="id" validate={[required()]} />
      <TextInput label={useTxtData.table.WeiXin_OpenID} source="WeiXin_OpenID" validate={[required()]} />
      <TextInput label={useTxtData.table.UserName} source="UserName" validate={[required()]} />
      <TextInput label={useTxtData.table.UserMobile} source="UserMobile" validate={[required()]} />
      <TextInput label={useTxtData.table.UserPwd} source="UserPwd" validate={[required()]} />
      <TextInput label={useTxtData.table.UserSex} source="UserSex" validate={[required()]} />
      <TextInput label={useTxtData.table.UserTouXiang} source="UserTouXiang" validate={[required()]} />
      <TextInput label={useTxtData.table.CreateTime} source="CreateTime" validate={[required()]} />
      <TextInput label={useTxtData.table.LastLoginTime} source="LastLoginTime" validate={[required()]} />
    </SimpleForm>
  );
}