import * as React from 'react';
import { Fragment } from 'react';
import { makeStyles } from 'tss-react/mui';
import { required, minLength, maxLength, minValue, maxValue, number, regex, email, choices, Form, useResourceContext, useNotify, FunctionField } from 'react-admin';
import {
  useListContext,
  useRedirect,
  List,
  Datagrid,
  Filter,
  Edit,
  Create,

  Toolbar,
  TopToolbar,
  DeleteWithConfirmButton,
  BulkDeleteWithConfirmButton,
  Show,
  SimpleShowLayout,

  ReferenceField,
  TextField,
  ImageField,
  BooleanField,

  SimpleForm,
  ReferenceInput,
  SelectInput,
  TextInput,
  NumberInput,
  BooleanInput,
  NullableBooleanInput,

  Button,
  EditButton,
  ShowButton,
  SaveButton,
} from 'react-admin';
import { useForm, useFormContext } from 'react-hook-form';
import { Button as MButton, Box, useMediaQuery, Stack } from '@mui/material';
import { PreviewImage, UpLoadFile } from './custom/UpLoadFile';
import ArrowBackIcon from '@mui/icons-material/ArrowBack';
import SearchIcon from '@mui/icons-material/Search';
import ClearAllIcon from '@mui/icons-material/ClearAll';
//import ResetOrderNum from './Message_ResetOrderNum';

{/* 
  import { MessageList, MessageCreate, MessageEdit, MessageShow } from './components/Message';

  <Resource name="CMSMessage" list={MessageList} create={MessageCreate} edit={MessageEdit} show={MessageShow} />
  <Resource name="CMSMessage" list={ListGuesser} create={EditGuesser} edit={EditGuesser} edit={ShowGuesser} /> 
*/}

const useStyles = makeStyles()((theme) => {
  return {
    imageField: {
      '& img.RaImageField-image': { width: 60 }
    },
  };
});


const useTxtData = {
  page: {
    tableName: 'Message',
  },
  table: {
    id: 'id',
    MemberId: '会员',
    CunMingId: '村民',
    MessageType: '类型',
    Title: '内容',
    Content: '内容2',
    Pics: '图片',
    Name: '姓名',
    Mobile: '手机号',
    CreateTime: '时间',
  }
};

//分页列表页面
export const MessageList = () => {
  const isSmall = useMediaQuery((theme: any) => theme.breakpoints.down('sm'));
  // 筛选器模块
  // const Filters = () => {
  //   return (
  //     <Filter>
  //       <TextInput label="ID" source="id" alwaysOn />
  //     </Filter>
  //   );
  // };
  const MobileFilters = [
    <TextInput label="关键词" source="Key" alwaysOn resettable />
  ];
  const Filters = (props: any) => {
    const { displayedFilters, filterValues, setFilters, hideFilter, refetch } = useListContext();
    if (isSmall) return null;
    if (props.context === "button") return null;
    const onSubmit = (values: any) => { if (Object.keys(values).length > 0) { setFilters(values, displayedFilters); } else { setFilters({}, displayedFilters); } refetch(); };
    const resetFilter = () => { setFilters({}, displayedFilters); };
    return (
      <div>
        <Form onSubmit={onSubmit} defaultValues={filterValues}>
          <Stack direction={'row'} alignItems={'baseline'} spacing={1.5}>
            <TextInput label="关键词" source="Key" alwaysOn resettable />
            <MButton variant="contained" color="primary" type="submit" startIcon={<SearchIcon />}>搜索</MButton>
            <MButton variant="outlined" onClick={resetFilter} startIcon={<ClearAllIcon />}>重置</MButton>
          </Stack>
        </Form>
      </div>
    );
  };

  //批量操作模块
  const PostBulkActionButtons = () => {
    return (
      <Fragment>
        {/* <ResetOrderNum label="重置排序"  /> */}
        {/* <BulkDeleteWithConfirmButton confirmTitle="删除确认" confirmContent="确认要删除所选记录吗？" mutationMode='undoable' /> */}
      </Fragment>
    );
  };
  //行右侧功能按钮
  const RowAction = () => {
    return (
      <div style={{ textAlign: 'right' }}>
        {/* <EditButton /><br /> */}
        <ShowButton /><br />
        {/* <DeleteWithConfirmButton confirmTitle="删除确认" confirmContent="确认要删除该记录吗？" /> */}
      </div>
    );
  }

  const { classes } = useStyles();
  return (
    <List
      title={useTxtData.page.tableName + '列表'}
      sort={{ field: 'id', order: 'DESC' }}
      // filters={<Filters />}
      exporter={false}
      sx={{ "& .MuiToolbar-root .MuiToolbar-root": { height: 0, minHeight: 0 } }}
    >
      <Datagrid
        // bulkActionButtons={<PostBulkActionButtons />}
        bulkActionButtons={false}
      >
        {/* <TextField source="id" /> */}
        {/* <TextField label="名称" source="Name" /> */}
        {/* <TextField label="排序" source="OrderNum" />  */}
        <TextField label={useTxtData.table.id} source="id" />

        {/* <TextField label={useTxtData.table.MemberId} source="MemberId" /> */}
        {/* <TextField label={useTxtData.table.CunMingId} source="CunMingId" /> */}
        <FunctionField label={'会员/村民'} render={({ MemberName, CunMingName }: any) =>
          <>
            <span>{MemberName}</span><br />
            <span>{CunMingName}</span>
          </>
        } />

        <TextField label={useTxtData.table.MessageType} source="MessageType" />
        {/* <TextField label={useTxtData.table.Title} source="Title" /> */}
        {/* <TextField label={useTxtData.table.Content} source="Content" /> */}
        <FunctionField label={'内容'} render={({ Title, Content }: any) =>
          <>
            <span>{Title}</span><br />
            <span>{Content}</span>
          </>
        } />
        {/* <TextField label={useTxtData.table.Pics} source="Pics" /> */}
        <FunctionField label={useTxtData.table.Pics} render={({ Pics }: any) => {
          let picArr = (Pics ?? "").split(',');
          console.log('picArr', picArr)
          if (picArr.length == 1 && picArr[0].length == 0) return null;
          return (<Stack flexDirection={'row'} sx={{ gap: 0.5 }}>
            {picArr.map((url: any) =>
              <Stack sx={{ "& img": { width: 60, height: 60 } }}>
                <img src={url} />
              </Stack>
            )}
          </Stack>
          )
        }
        } />
        {/* <TextField label={useTxtData.table.Name} source="Name" /> */}
        {/* <TextField label={useTxtData.table.Mobile} source="Mobile" /> */}
        <FunctionField label={'身份信息'} render={({ Name, Mobile }: any) =>
          <>
            <span>{Name}</span><br />
            <span>{Mobile}</span>
          </>
        } />
        <TextField label={useTxtData.table.CreateTime} source="CreateTime" />
        <RowAction />
      </Datagrid>
    </List>
  );
};

////////////////////////////////////////////////////////////////////////
////////////////////////////////////////////////////////////////////////

//SHOW页面
export const MessageShow = () => {
  const ShowActions = () => {
    const resource = useResourceContext();
    const redirect = useRedirect();
    return (
      <TopToolbar>
        <Button label="返回列表" onClick={() => redirect(`/${resource}`)} variant="text">
          <ArrowBackIcon />
        </Button>
        <EditButton />
      </TopToolbar>
    );
  };

  const { classes } = useStyles();
  return (
    <Show title={'查看' + useTxtData.page.tableName} actions={<ShowActions />}>
      <SimpleShowLayout>
        {/* <TextField source="id" /> */}
        {/* <TextField label="名称" source="Name" /> */}
        {/* <TextField label="排序" source="OrderNum" />  */}
        <TextField label={useTxtData.table.id} source="id" />
        <TextField label={useTxtData.table.MemberId} source="MemberId" />
        <TextField label={useTxtData.table.CunMingId} source="CunMingId" />
        <TextField label={useTxtData.table.MessageType} source="MessageType" />
        <TextField label={useTxtData.table.Title} source="Title" />
        <TextField label={useTxtData.table.Content} source="Content" />
        {/* <TextField label={useTxtData.table.Pics} source="Pics" /> */}
        <FunctionField label={useTxtData.table.Pics} render={({ Pics }: any) => {
          let picArr = (Pics ?? "").split(',');
          console.log('picArr', picArr)
          if (picArr.length == 1 && picArr[0].length == 0) return null;
          return (<Stack flexDirection={'row'} sx={{ gap: 0.5 }}>
            {picArr.map((url: any) =>
              <Stack sx={{ "& img": { width: 120, height: 120 } }}>
                <img src={url} />
              </Stack>
            )}
          </Stack>
          )
        }
        } />
        <TextField label={useTxtData.table.Name} source="Name" />
        <TextField label={useTxtData.table.Mobile} source="Mobile" />
        <TextField label={useTxtData.table.CreateTime} source="CreateTime" />
      </SimpleShowLayout>
    </Show>
  );
}

////////////////////////////////////////////////////////////////////////
////////////////////////////////////////////////////////////////////////

//编辑页面
export const MessageEdit = () => {
  const resource = useResourceContext();
  const redirect = useRedirect();

  const PostEditActions = () => {
    return (
      <TopToolbar >
        <Button label="返回列表" onClick={() => redirect(`/${resource}`)} variant="text">
          <ArrowBackIcon />
        </Button>
      </TopToolbar>
    );
  };
  const PostEditToolbar = () => {
    return (
      <Toolbar >
        <SaveButton />
      </Toolbar>
    );
  };
  return (
    <Edit redirect={`/${resource}`} title={'编辑' + useTxtData.page.tableName} actions={<PostEditActions />}>
      <MyForm Edit={true} toolbar={<PostEditToolbar />} />
    </Edit>
  );
};

////////////////////////////////////////////////////////////////////////
////////////////////////////////////////////////////////////////////////

//创建页面
export const MessageCreate = () => {
  const resource = useResourceContext();
  const redirect = useRedirect();
  const notify = useNotify();

  const PostCreateActions = () => {
    return (
      <TopToolbar>
        <Button label="返回列表" onClick={() => redirect(`/${resource}`)} variant="text" >
          <ArrowBackIcon />
        </Button>
      </TopToolbar>
    );
  };
  const PostCreateToolbar = () => {
    const { reset, resetField, handleSubmit, setValue, getValues, } = useFormContext();
    return (
      <Toolbar>
        <SaveButton alwaysEnable label="保存&列表" />
        <SaveButton alwaysEnable type='button' label="保存&继续" variant="text" mutationOptions={{
          onSuccess: () => {
            notify('创建成功', { type: 'success' });
            reset();
          }
        }} />
      </Toolbar>
    );
  };

  return (
    <Create redirect={`/${resource}`} title={'新增' + useTxtData.page.tableName} actions={<PostCreateActions />}>
      <MyForm Create={true} toolbar={<PostCreateToolbar />} />
    </Create>
  );
};

////////////////////////////////////////////////////////////////////////
////////////////////////////////////////////////////////////////////////
//Create和Edit公用表单
const MyForm = (props: any) => {
  let { Edit, Create } = props;
  return (
    <SimpleForm {...props} >
      {/* <TextInput source="Name" /> */}
      {/* <NumberInput source="OrderNum" /> */}
      <TextInput label={useTxtData.table.id} source="id" validate={[required()]} />
      <TextInput label={useTxtData.table.MemberId} source="MemberId" validate={[required()]} />
      <TextInput label={useTxtData.table.CunMingId} source="CunMingId" validate={[required()]} />
      <TextInput label={useTxtData.table.MessageType} source="MessageType" validate={[required()]} />
      <TextInput label={useTxtData.table.Title} source="Title" validate={[required()]} />
      <TextInput label={useTxtData.table.Content} source="Content" validate={[required()]} />
      <TextInput label={useTxtData.table.Pics} source="Pics" validate={[required()]} />
      <TextInput label={useTxtData.table.Name} source="Name" validate={[required()]} />
      <TextInput label={useTxtData.table.Mobile} source="Mobile" validate={[required()]} />
      <TextInput label={useTxtData.table.CreateTime} source="CreateTime" validate={[required()]} />
    </SimpleForm>
  );
}