import * as React from 'react';
import { Fragment } from 'react';
import { makeStyles } from 'tss-react/mui';
import { required, minLength, maxLength, minValue, maxValue, number, regex, email, choices, Form, useResourceContext, useNotify } from 'react-admin';
import {
    useListContext,
    useRedirect,
    List,
    Datagrid,
    Filter,
    Edit,
    Create,

    Toolbar,
    TopToolbar,
    DeleteWithConfirmButton,
    BulkDeleteWithConfirmButton,
    Show,
    SimpleShowLayout,

    ReferenceField,
    TextField,
    ImageField,
    BooleanField,

    SimpleForm,
    ReferenceInput,
    SelectInput,
    TextInput,
    NumberInput,
    BooleanInput,
    NullableBooleanInput,

    Button,
    EditButton,
    ShowButton,
    SaveButton,
} from 'react-admin';
import { useForm, useFormContext } from 'react-hook-form';
import { Button as MButton, Box, useMediaQuery, Stack } from '@mui/material';
import { PreviewImage, UpLoadFile } from './custom/UpLoadFile';
import ArrowBackIcon from '@mui/icons-material/ArrowBack';
import SearchIcon from '@mui/icons-material/Search';
import ClearAllIcon from '@mui/icons-material/ClearAll';
//import ResetOrderNum from './Admin_ResetOrderNum';

{/* 
  import { AdminList, AdminCreate, AdminEdit, AdminShow } from './components/Admin';

  <Resource name="CMSAdmin" list={AdminList} create={AdminCreate} edit={AdminEdit} show={AdminShow} />
  <Resource name="CMSAdmin" list={ListGuesser} create={EditGuesser} edit={EditGuesser} edit={ShowGuesser} /> 
*/}

const useStyles = makeStyles()((theme) => {
    return {
        imageField: {
            '& img': { width: 60 }
        },
    };
});

const useTxtData = {
    page: {
        tableName: 'Admin',
    },
    table: {
        id: 'id',
        Account: '账号',
        NickName: '昵称',
        Password: '密码',
        AdminType: '账号类型',
        Sex: '性别',
        Mobile: '手机',
        Email: '邮箱',
        Avatar: '头像',
        CreateTime: '创建时间',
        UpdateTime: '更新时间',
        Open: '开启',
        Remark: '备注',
    }
};

//分页列表页面
export const AdminList = () => {
    const isSmall = useMediaQuery((theme: any) => theme.breakpoints.down('sm'));
    // 筛选器模块
    // const Filters = () => {
    //   return (
    //     <Filter>
    //       <TextInput label="ID" source="id" alwaysOn />
    //     </Filter>
    //   );
    // };
    const MobileFilters = [
        <TextInput label="关键词" source="Key" alwaysOn resettable />
    ];
    const Filters = (props: any) => {
        const { displayedFilters, filterValues, setFilters, hideFilter, refetch } = useListContext();
        if (isSmall) return null;
        if (props.context === "button") return null;
        const onSubmit = (values: any) => { if (Object.keys(values).length > 0) { setFilters(values, displayedFilters); } else { setFilters({}, displayedFilters); } refetch(); };
        const resetFilter = () => { setFilters({}, displayedFilters); };
        return (
            <div>
                <Form onSubmit={onSubmit} defaultValues={filterValues}>
                    <Stack direction={'row'} alignItems={'baseline'} spacing={1.5}>
                        <TextInput label="关键词" source="Key" alwaysOn resettable />
                        <MButton variant="contained" color="primary" type="submit" startIcon={<SearchIcon />}>搜索</MButton>
                        <MButton variant="outlined" onClick={resetFilter} startIcon={<ClearAllIcon />}>重置</MButton>
                    </Stack>
                </Form>
            </div>
        );
    };

    //批量操作模块
    const PostBulkActionButtons = () => {
        return (
            <Fragment>
                {/* <ResetOrderNum label="重置排序"  /> */}
                {/* <BulkDeleteWithConfirmButton confirmTitle="删除确认" confirmContent="确认要删除所选记录吗？" mutationMode='undoable' /> */}
            </Fragment>
        );
    };
    //行右侧功能按钮
    const RowAction = () => {
        return (
            <div style={{ textAlign: 'right' }}>
                <EditButton /><br />
                <ShowButton /><br />
                <DeleteWithConfirmButton confirmTitle="删除确认" confirmContent="确认要删除该记录吗？" />
            </div>
        );
    }

    return (
        <List
            title={useTxtData.page.tableName + '列表'}
            sort={{ field: 'id', order: 'DESC' }}
            // filters={isSmall ? MobileFilters : <Filters />}
            exporter={false}
            sx={{ "& .MuiToolbar-root .MuiToolbar-root": { height: 0, minHeight: 0 } }}
        >
            <Datagrid
                // bulkActionButtons={<PostBulkActionButtons />}
                bulkActionButtons={false}
            >
                {/* <TextField source="id" /> */}
                {/* <TextField label="名称" source="Name" /> */}
                {/* <TextField label="排序" source="OrderNum" />  */}
                <TextField label={useTxtData.table.id} source="id" />
                <TextField label={useTxtData.table.Account} source="Account" />
                <TextField label={useTxtData.table.NickName} source="NickName" />
                {/* <TextField label={useTxtData.table.Password} source="Password" /> */}
                <TextField label={useTxtData.table.AdminType} source="AdminType" />
                <TextField label={useTxtData.table.Sex} source="Sex" />
                {/* <TextField label={useTxtData.table.Mobile} source="Mobile" />
                <TextField label={useTxtData.table.Email} source="Email" /> */}
                <TextField label={useTxtData.table.Avatar} source="Avatar" />
                {/* <TextField label={useTxtData.table.CreateTime} source="CreateTime" /> */}
                {/* <TextField label={useTxtData.table.UpdateTime} source="UpdateTime" /> */}
                <TextField label={useTxtData.table.Open} source="Open" />
                {/* <TextField label={useTxtData.table.Remark} source="Remark" /> */}
                <RowAction />
            </Datagrid>
        </List>
    );
};

////////////////////////////////////////////////////////////////////////
////////////////////////////////////////////////////////////////////////

//SHOW页面
export const AdminShow = () => {
    const ShowActions = () => {
        const resource = useResourceContext();
        const redirect = useRedirect();
        return (
            <TopToolbar>
                <Button label="返回列表" onClick={() => redirect(`/${resource}`)} variant="text">
                    <ArrowBackIcon />
                </Button>
                <EditButton />
            </TopToolbar>
        );
    };

    const classes = useStyles();
    return (
        <Show title={'查看' + useTxtData.page.tableName} actions={<ShowActions />}>
            <SimpleShowLayout>
                {/* <TextField source="id" /> */}
                {/* <TextField label="名称" source="Name" /> */}
                {/* <TextField label="排序" source="OrderNum" />  */}
                <TextField label={useTxtData.table.id} source="id" />
                <TextField label={useTxtData.table.Account} source="Account" />
                <TextField label={useTxtData.table.NickName} source="NickName" />
                <TextField label={useTxtData.table.Password} source="Password" />
                <TextField label={useTxtData.table.AdminType} source="AdminType" />
                <TextField label={useTxtData.table.Sex} source="Sex" />
                <TextField label={useTxtData.table.Mobile} source="Mobile" />
                <TextField label={useTxtData.table.Email} source="Email" />
                <TextField label={useTxtData.table.Avatar} source="Avatar" />
                <TextField label={useTxtData.table.CreateTime} source="CreateTime" />
                <TextField label={useTxtData.table.UpdateTime} source="UpdateTime" />
                <TextField label={useTxtData.table.Open} source="Open" />
                <TextField label={useTxtData.table.Remark} source="Remark" />
            </SimpleShowLayout>
        </Show>
    );
}

////////////////////////////////////////////////////////////////////////
////////////////////////////////////////////////////////////////////////

//编辑页面
export const AdminEdit = () => {
    const resource = useResourceContext();
    const redirect = useRedirect();

    const PostEditActions = () => {
        return (
            <TopToolbar >
                <Button label="返回列表" onClick={() => redirect(`/${resource}`)} variant="text">
                    <ArrowBackIcon />
                </Button>
            </TopToolbar>
        );
    };
    const PostEditToolbar = () => {
        return (
            <Toolbar >
                <SaveButton />
            </Toolbar>
        );
    };
    return (
        <Edit redirect={`/${resource}`} title={'编辑' + useTxtData.page.tableName} actions={<PostEditActions />}>
            <MyForm Edit={true} toolbar={<PostEditToolbar />} />
        </Edit>
    );
};

////////////////////////////////////////////////////////////////////////
////////////////////////////////////////////////////////////////////////

//创建页面
export const AdminCreate = () => {
    const resource = useResourceContext();
    const redirect = useRedirect();
    const notify = useNotify();

    const PostCreateActions = () => {
        return (
            <TopToolbar>
                <Button label="返回列表" onClick={() => redirect(`/${resource}`)} variant="text" >
                    <ArrowBackIcon />
                </Button>
            </TopToolbar>
        );
    };
    const PostCreateToolbar = () => {
        const { reset, resetField, handleSubmit, setValue, getValues, } = useFormContext();
        return (
            <Toolbar>
                <SaveButton alwaysEnable label="保存&列表" />
                <SaveButton alwaysEnable type='button' label="保存&继续" variant="text" />
            </Toolbar>
        );
    };

    return (
        <Create redirect={`/${resource}`} title={'新增' + useTxtData.page.tableName} actions={<PostCreateActions />}>
            <MyForm Create={true} toolbar={<PostCreateToolbar />} />
        </Create>
    );
};

////////////////////////////////////////////////////////////////////////
////////////////////////////////////////////////////////////////////////
//Create和Edit公用表单
const MyForm = (props: any) => {
    let { Edit, Create } = props;
    return (
        <SimpleForm>
            {/* <TextInput source="Name" /> */}
            {/* <NumberInput source="OrderNum" /> */}
            <TextInput label={useTxtData.table.id} source="id" validate={[required()]} />
            <TextInput label={useTxtData.table.Account} source="Account" validate={[required()]} />
            <TextInput label={useTxtData.table.NickName} source="NickName" validate={[required()]} />
            <TextInput label={useTxtData.table.Password} source="Password" validate={[required()]} />
            <TextInput label={useTxtData.table.AdminType} source="AdminType" validate={[required()]} />
            <TextInput label={useTxtData.table.Sex} source="Sex" validate={[required()]} />
            <TextInput label={useTxtData.table.Mobile} source="Mobile" validate={[required()]} />
            <TextInput label={useTxtData.table.Email} source="Email" validate={[required()]} />
            <TextInput label={useTxtData.table.Avatar} source="Avatar" validate={[required()]} />
            <TextInput label={useTxtData.table.CreateTime} source="CreateTime" validate={[required()]} />
            <TextInput label={useTxtData.table.UpdateTime} source="UpdateTime" validate={[required()]} />
            <TextInput label={useTxtData.table.Open} source="Open" validate={[required()]} />
            <TextInput label={useTxtData.table.Remark} source="Remark" validate={[required()]} />
        </SimpleForm>
    );
}