import { Admin, Resource, ListGuesser, CustomRoutes, usePermissions } from "react-admin";
import jsonServerProvider from "ra-data-json-server";
import MyLogin from '../loginPage';
import authProvider from './authProvider';
import dataProvider from "./dataProvider";
import polyglotI18nProvider from 'ra-i18n-polyglot';
import chinese from './chinese';
import { Route } from "react-router-dom";
import ChangePwd from "../customLayout/ChangePwd";
import SettingsIcon from '@mui/icons-material/Settings';
import CreditScoreIcon from '@mui/icons-material/CreditScore';


import MyLayout from "./customLayout/layout";
import Dashboard from "./components/dashboardPage";
import FormatListBulletedIcon from '@mui/icons-material/FormatListBulleted';
import SupervisorAccountIcon from '@mui/icons-material/SupervisorAccount';
// const dataProvider = jsonServerProvider("https://jsonplaceholder.typicode.com");

import { AdminList, AdminCreate, AdminEdit, AdminShow } from './components/Admin';
import { InfoList, InfoCreate, InfoEdit, InfoShow } from './components/Info';
import { MemberList, MemberCreate, MemberEdit, MemberShow } from './components/Member';
import { CunMingList, CunMingCreate, CunMingEdit, CunMingShow } from './components/CunMing';
import { CunMingJifenList, CunMingJifenCreate, CunMingJifenEdit, CunMingJifenShow } from './components/CunMingJifen';
import { MessageList, MessageCreate, MessageEdit, MessageShow } from './components/Message';

//设置语言为 中文
const i18nProvider = polyglotI18nProvider(() => chinese as any, 'zh', { allowMissing: true });
const App = () => {
    return (
        <Admin
            title="后台管理"
            loginPage={MyLogin}
            layout={MyLayout}
            dashboard={Dashboard}
            authProvider={authProvider}
            dataProvider={dataProvider}
            i18nProvider={i18nProvider}
        >
            {(permissions) => {
                console.log('permissions', permissions)
                // let isAdmin = permissions === 'Admin';
                return [
                    <Resource options={{ group: '设置', label: '管理员', icon: <SupervisorAccountIcon /> }} name="Admin/CMSAdmin" list={AdminList} create={AdminCreate} edit={AdminEdit} />,

                    <Resource options={{ group: '信息', label: '信息列表', icon: <SupervisorAccountIcon /> }} name="Admin/CMSInfo" list={InfoList} create={InfoCreate} edit={InfoEdit} />,
                    <Resource options={{ group: '信息', label: '留言列表', icon: <SupervisorAccountIcon /> }} name="Admin/CMSMessage" list={MessageList} show={MessageShow} />,

                    <Resource options={{ group: '成员', label: '会员列表', icon: <SupervisorAccountIcon /> }} name="Admin/CMSMember" list={MemberList} create={MemberCreate} edit={MemberEdit} show={MemberShow} />,
                    <Resource options={{ group: '成员', label: '村民信息', icon: <SupervisorAccountIcon /> }} name="Admin/CMSCunMing" list={CunMingList} create={CunMingCreate} edit={CunMingEdit} show={CunMingShow} />,
                    <Resource options={{ group: '成员', label: '积分记录', icon: <SupervisorAccountIcon /> }} name="Admin/CMSCunMingJifen" list={CunMingJifenList} create={CunMingJifenCreate} edit={CunMingJifenEdit} show={CunMingJifenShow} />,


                    <CustomRoutes>
                        <Route path="/ChangePwd" element={<ChangePwd />} />
                    </CustomRoutes>,
                ]
            }}
        </Admin>
    );
};

export default App;